
import React, {useEffect, useState} from 'react';
const paperSvg = ({name, subject, opacity}) => {
    return (
        <>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="147px" height="169px" viewBox="0 0 147 169" version="1.1" opacity="{opacity}">
    <defs>
        <polygon id="path-1" points="0 0 118 0 135 17 134.571429 157 0 157"/>
        <filter x="-6.7%" y="-5.7%" width="113.3%" height="111.5%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="2" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.151688156 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
            <feOffset dx="-2" dy="-2" in="SourceAlpha" result="shadowOffsetOuter2"/>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"/>
                <feMergeNode in="shadowMatrixOuter2"/>
            </feMerge>
        </filter>
    </defs>
    <g id="June-5,-2024" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assignments" transform="translate(6, 6)">
            <g id="Group-17">
                <g id="Rectangle">
                    <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"/>
                    <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"/>
                </g>
                <path d="M118,0 L126,8 L135,17 L123,17 C120.238576,17 118,14.7614237 118,12 L118,0 L118,0 Z" id="Rectangle" fill="#F6F6F6"/>
            </g>
            <foreignObject x="12" y="25" width="90" height="100"><div xmlns="http://www.w3.org/1999/xhtml" style={{color: "black",fontSize: "9.6px",fontFamily: "Lato,sans-serif",paddingLeft: "7px",wordBreak: "break-word",lineHeight: "16px",height: "16px"}}>{name}</div></foreignObject>
            {/* <text id="&lt;Assignment-Name&gt;" fontFamily="OpenSans, Open Sans" fontSize="11" fontWeight="normal" fill="#000000">
                <tspan x="12" y="25"></tspan>
            </text> */}
            <text id="&lt;Subject&gt;" fontFamily="OpenSans, Open Sans" fontSize="9" fontWeight="normal" fill="#000000">
                <tspan x="12" y="147">{subject}</tspan>
            </text>
        </g>
    </g>
</svg>
        </>
    );
}
export default paperSvg;
