import React, {useEffect, useState} from 'react';
import {Form, Button} from 'react-bootstrap';
import Swal from 'sweetalert2';

import {Amplify, Auth, API} from 'aws-amplify';
import { CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import awsmobile from '../../aws-exports';
import amplifyconfig from '../../amplifyconfiguration.json';

import LoginImage from '../../images/login.png';
import Logo from '../../images/CBSE-login.svg';
// import SetupForm from '../SetupForm/SetupForm';
// import PageMajikEditor from '../PageMajikEditor/PageMajikEditor';
import ContestShelf from '../ContestShelf/ContestShelf'
import "./Login.css";
const initialSignInData = {
  username: '',
  password: ''
}

Amplify.configure(amplifyconfig);
Amplify.configure(awsmobile);
const Login = (props) => {
    const [signInForm, setSignInForm] = useState(initialSignInData);
    const [signInMode, setSignInMode] = useState(true);
    const [buttonClasses, setButtonClasses] = useState("sign-in ld-ext-right");
    const [userId, setUserId] = useState("");
    
    const [userLoggedInData, setUserLoggedInData ] = useState({});
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [ValidataionPassword , setValidationPassword] = useState("text-muted page-hide");
    const [ValidataionUserClasses, setValidationUser] = useState("text-muted page-hide")

    const [openSetupForm, setOpenSetupForm] = useState(false);
    
    const [fileObj, setfileObj] = useState([{}]);



async function logintoCognito(e){
  typeof e != "undefined" && e.preventDefault();
  setButtonClasses("sign-in ld-ext-right running");
  try{ 
      //const user = await Auth.signIn(signInForm.username.toLowerCase(), signInForm.password);
      const apiName = "apicontestassignment";
      const path = "/contest/verifyuser";
      const init = {
        body : {
          username : signInForm.username.trim().toLowerCase(), 
          password : signInForm.password.trim()
        }
      }
      
      API.post(apiName,path,init).then(async function(response) {
        // setusername(response.username);
        const cognito = JSON.parse(response.body);
        const {IdToken, AccessToken, RefreshToken}   = cognito.cognitoUser;
        const idToken = new CognitoIdToken({ IdToken: IdToken });
        const accessToken = new CognitoAccessToken({ AccessToken: AccessToken });
        const refreshToken = new CognitoRefreshToken({ RefreshToken: RefreshToken });
        const session = new CognitoUserSession({
            IdToken: idToken,
            AccessToken: accessToken,
            RefreshToken: refreshToken
        });
        //await Auth.setSignInUserSession(session);
        //   await Auth.setCognitoUserSession(session);

        //   // 3. Update Cognito credentials
        //   await Auth.currentCredentials();   
        //   const user = await Auth.currentAuthenticatedUser();
        //   console.log("user",user);
        if (cognito.cognitoUser) {
          // Use the returned Cognito user to set the current user in Amplify

        //   const signedInUser = await Auth.signIn("mari", "mari@1234");
          const signedInUser = await Auth.federatedSignIn(
            { token: cognito.cognitoUser.IdToken },
            cognito.cognitoUser
          );
          console.log("sigined In",signedInUser);
          setSignInMode(false);
          // setUserLoggedIn(true);
          //setUserLoggedInData(user);
          //sessionStorage.setItem("pm-users",user);
        //   if(signedInUser.authenticated){
            //setusersignedIn(true);
            
        //   }
          // Redirect to dashboard or home page
          // history.push('/dashboard');
        }else{          
          // Swal.fire({
          //   icon: "error",
          //   title: message,
          //   showConfirmButton: true,
          //   confirmButtonText: "OK",
          // });
        }
      }).catch(err=>{
        console.log(err);
        let message = err.message;
        if(err.response.status === 404){
          message = "Invalid username or password."
        }else if(err.response.status === 401){
          message = "You haven't signed up yet. Please contact CBSE board for registration."
        }
        signInErrorHandler(err);
        Swal.fire({
          icon: "error",
          title: message,
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        setButtonClasses("sign-in ld-ext-right");
      });
      // sessionStorage.setItem("pm-users",user);  
      // console.log(user);
      // setUserId(user.attributes?.sub);
      
      //getAllContest(signInForm.username.toLowerCase());
    }catch(err){
      console.log(err);
        signInErrorHandler(err);  
    }
  
  //signIn();
}




function signInErrorHandler(error){
  if(error.code==="UserNotConfirmedException"){
    // setAccountVerificationMode(true);
    // setVerificationForm(initialVerifyData);
  }else if(error.code==="UserNotFoundException"){
    //getChapterDetails(true);
    Swal.fire({
      icon: 'error',
      title: error.message,
      showConfirmButton: true,
      confirmButtonText: 'Ok'
    })
   
  }else if(error.code==="NotAuthorizedException"){
    Swal.fire({
      icon: 'error',
      title: "Invalid username or password.",
      showConfirmButton: true,
      confirmButtonText: 'OK'
    });
    setButtonClasses("sign-in ld-ext-right");
   
  }else if("InvalidParameterException" === error.code){
    Swal.fire({
      icon: 'error',
      title: "Invalid username or password.",
      showConfirmButton: true,
      confirmButtonText: 'OK'
    });
    setButtonClasses("sign-in ld-ext-right");
  }
  setButtonClasses("sign-in ld-ext-right"); /**Exception handling loader */
   //alert(error);
   console.log(error.code);
   console.log(error);
}




    async function signIn(e) {
        typeof e != "undefined" && e.preventDefault();
        if(signInForm.username.trim().length === 0 || signInForm.password.trim().length === 0){
          if(signInForm.username.trim().length === 0){
            setValidationUser("text-muted");
          }
          if(signInForm.password.trim().length === 0){
            setValidationPassword("text-muted");
          }
          return false;
        }
        setButtonClasses("sign-in ld-ext-right running");

       // getChapterDetails(true);    
       
    }
    function signOut(){

     typeof window.editor != "undefined" && window.editor.destroy();
      setSignInMode(true);
      setUserLoggedIn(false);
      setSignInForm({
        username: '',
        password: ''
      });
      setButtonClasses("sign-in ld-ext-right");
      setUserLoggedInData({});
      setValidationPassword("text-muted page-hide");
      setValidationUser("text-muted page-hide");
      sessionStorage.removeItem("pm-users");
    }
    const signInInputChangeHandler = (e) => {
          var tempVal = {
            ...signInForm
          }
          tempVal[e.target.name] = e.target.value;
          setSignInForm(tempVal);
          setValidationUser("text-muted page-hide");
          setValidationPassword("text-muted page-hide");
    }
    async function siginUser(userDetails){
      try {
        if(userDetails.Items.length > 0){
         

          // const user = await Auth.signIn(signInForm.username.trim(), signInForm.password.trim());
          // console.log(user);
          // if(user.username.toLowerCase() === signInForm.username.toLowerCase()){
          //   setUserLoggedIn(true);
          //   setUserLoggedInData(user);
          //   sessionStorage.setItem("pm-users",user);
          // }else{
          //   checkUserRegisterForContest(user.username);
          // }
        }else{
          Swal.fire({
            icon: "info",
            title: `You haven't signed up yet. Please contact CBSE board for registration.`,            
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
          setButtonClasses("sign-in ld-ext-right");
        }
        
           
      } catch (error) {
        
        if(error.code==="UserNotConfirmedException"){
          // setAccountVerificationMode(true);
          // setVerificationForm(initialVerifyData);
        }else if(error.code==="UserNotFoundException"){
          getChapterDetails(true);
          // Swal.fire({
          //   icon: 'error',
          //   title: error.message,
          // showConfirmButton: true,
          // confirmButtonText: 'Ok'
          // })
         
        }else if(error.code==="NotAuthorizedException"){
          Swal.fire({
            icon: 'error',
            title: "Invalid username or password.",
            showConfirmButton: true,
            confirmButtonText: 'OK'
          });
          setButtonClasses("sign-in ld-ext-right");
         
        }else if("InvalidParameterException" === error.code){
          Swal.fire({
            icon: 'error',
            title: "Invalid username or password.",
            showConfirmButton: true,
            confirmButtonText: 'OK'
          });
          setButtonClasses("sign-in ld-ext-right");
        }
        setButtonClasses("sign-in ld-ext-right"); /**Exception handling loader */
         //alert(error);
         console.log(error.code);
         console.log(error);
      }
      
    }
    async function getChapterDetails(checkUser=false){
      
       const contestId = props.contestId;   
       const apiName = "contestAPI";
       const path = "/upgrade/chapters";
       const init = {
         response : true,
          queryStringParameters : {
            contestId,
            username : signInForm.username.toLowerCase().trim()
          }
        };
       API.get(apiName,path,init).then(response=>{
        if(checkUser === true){
          siginUser(response.data.chapters);
        }else{
          processChapterResponse(response.data.chapters);
        }
          
       }).catch(err=>{
          Swal.fire({
          icon: "error",
          title: err.message,
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
       });  
       
    }

    async function processChapterResponse(data, checkUser=false){
      if(data.Items.length){
        if(checkUser === false){
          if(typeof data.Items[0].chapters != "undefined"){
            setfileObj(data.Items[0]);
            setSignInMode(false);
            setOpenSetupForm(false);
          }else{
            setfileObj(data.Items[0]);
            setSignInMode(false);
            setOpenSetupForm(true);
            document.querySelector('#main-login').removeAttribute('style');
            document.querySelector('#sub-login').removeAttribute('style');
          }
        }else{
          /**register user into cognito */
          const params = {
            username : signInForm.username,
            password : signInForm.password,
            attributes : {
              email : ''
            }
          }
          const {user} = await Amplify.Auth.signUp(params);
          signIn();
          // Swal.fire({
          //   icon: "Info",
          //   title: "",
          //   html : `You haven't signed up yet. Please visit the <a href="${props.contestURL}">${props.contestURL}</a> to register.`,
          //   showConfirmButton: true,
          //   confirmButtonText: "Ok",
          // });
        }
      }else{
        Swal.fire({
          icon: "info",
          title:  `You haven't signed up yet. Please contact CBSE board for registration.`,          
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
        setButtonClasses("sign-in ld-ext-right");
      }
    }
    function checkUserRegisterForContest(username){
      console.log("Checking username :",username);
    }

    // PageMaj!k
    useEffect(()=>{
          // Amplify.configure(awsconfig);
          setSignInForm({username:'', password:''});
    },[])
    
  function forgotPasswordHandler(){
    Swal.fire({
      icon: "info",
      title: `For password retrieval, please get in touch with the <br/>CBSE board.`,
      showConfirmButton: true,
      confirmButtonText: "OK",
    });
    
  }
  window.updateIndicator = function(evt){
    if ( evt.type ==="offline" ) {
      window.addPagemajikStorage();
      Swal.fire("", "Your network connection is lost. Reconnect to resume.", "warning", {
          button: "OK",
      });
  
    } else if ( evt.type === "online" ) {
        try{
        Swal.closeModal();
      }catch(err){}
        Swal.fire({
          icon: 'success',
          title : '',
          text: 'Connected',
          showConfirmButton: false,
          timer: 2000
        });
    }
    
  }
 return(
  <> {signInMode?
      <div id="main-login" >
 

    <div className="left-panel">
      <img className="words-img" src={LoginImage} alt=''/>
    </div>
  
  <div id="sub-login">
  <div className="right-panel">
      <img className="words-img" src={Logo} alt=''></img>
    </div>

    <div className='sublogin'>
    <div className='sub-login-page'>
  <Form.Group className='pm-welcome-group' autoComplete="off">
      {/* <span className="welcome"> WELCOME BACK TO</span> */}
      <span className="pg-pm">CBSE BUDDING AUTHORS PROGRAMME</span>
      {/* <span className="pg-words"> WORDSWORD</span> */}
    </Form.Group>

        <span className="login-head">LOGIN</span>
    
           <Form autoComplete="off" className='login-form'>
  <Form.Group className='pm-input-group' autoComplete="off">
    <span className="material-symbols-outlined">person</span>
    <Form.Label>Username</Form.Label>
    <input className="form-control username-phone" value={signInForm.username} autoComplete="off" type="text" name="username" id="username" placeholder="Enter username" onChange={signInInputChangeHandler} />
   
  </Form.Group>

   <div className="required">
    <Form.Text className={ValidataionUserClasses}>
    *Enter username
    </Form.Text></div>
  

  <Form.Group  className='pm-input-group pm-input-pwd'>
  <span className="material-symbols-outlined">
lock
</span>
    <Form.Label>Password</Form.Label>
    <input type="password" className="form-control" value={signInForm.password} autoComplete="new-password" id="password" placeholder="Enter password" name="password" onChange={signInInputChangeHandler} />
  </Form.Group>
  <div className="required">
    <Form.Text className={ValidataionPassword}>*Enter password</Form.Text>
    <Form.Label className="form-label-forget"><span  className="form-forget" style={{cursor:'pointer',}} onClick={forgotPasswordHandler}>Forgot Password</span></Form.Label>
  </div>


  <Form.Group className='signin-btn'>
  <Button variant="primary" className={buttonClasses} type="submit" onClick={logintoCognito}>
    LOGIN <span className='ld ld-ring ld-spin'></span>
  </Button></Form.Group>
  <br />


</Form> 
        </div></div></div>
        </div>
: <ContestShelf screenName={signInForm.username.toLowerCase()} userId={userId} signOut={signOut}/>}
        </>
    )
}

export default Login;