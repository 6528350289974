

export default  function chapterFormHeading(props){
    return(
        <>

<div className="imgholder">
                       
                       </div>
        <div className="chaphead">
                    
        <div className="welcome">
                        Welcome to <br/>
                        <span className="pg-chapauthor"> {props.contestName}</span></div>

                    <div className="pg-chapwords">
                       
                    </div>
                    
                </div>

                <div className="chapdesc">To get started, give us some information on what you will be working on today.
                </div>
        </>  
    );

}