import React, {useEffect, useState} from 'react';
import WordsWordLogo from '../../images/WordSword.svg';
import './Sidebar.css';
const Sidebar = (props) =>  {
  // const [openSection, setOpenSection] = useState(null);
  const {activeContent, data} = props;
  
  const [openAssignment, setAssignment] = useState(false);

  const [contestClassNames , setContestClassNames] = useState("list-wrap");

  const [assignmentClassNames , setAssignmentClassNames] = useState("list-wrap");
  
  const [openContest, setContest] = useState(true);
  const [activeSection, setActiveSection] = useState(activeContent);
  const [showLogout, setShowLogOut] = useState(false);
  
  // if(data.length === 1){
  //   setActiveSection(data[0].section);
  // } 
  const toggleAssignment= () =>{
    setAssignment(!openAssignment);
    //props.SectionClick("02");
    if(window.innerWidth <= 768){
      setContest(false);
      setContestClassNames("list-wrap");
      setAssignmentClassNames("list-wrap list-active");
    }
    
  }
  useEffect(() => {
    if(window.innerWidth <= 768){ 
      setContest(false);
    } 
    //setActiveSection(activeContent);
    
    
  },[]);
  useEffect(()=>{
    if(data.length === 1){
      setActiveSection(data[0].section);
    } else{
      setActiveSection("10");
    }
  },[data])
  const LogoutButtonHandler = ()=>{
    setShowLogOut(!showLogout);
  }
  const toggleContest =() =>{
    setContest(!openContest);
    if(window.innerWidth <= 768){
      setAssignment(false);
      setAssignmentClassNames("list-wrap");
      setContestClassNames("list-wrap list-active");
    }
  }
 
  const sectionClickHandler = (sectionType)=>{    
    setActiveSection(sectionType);
    props.onSectionClick(sectionType);
    if(window.innerWidth <= 768){
      if(sectionType.startsWith("0")){

        setAssignment(!openAssignment);
      } else if(sectionType.startsWith("1")){
        setContest(!openContest);
      }
    }
    
    
  }
  return (
    
    <div className="sidebar-contest">

    <div id="sidebar-heading">
      <div className='wordsword-logo' ><img src={WordsWordLogo} alt="WordsWord"/></div>
      <div className='sidebar-title'> <span className="icon-wrd-text">WORDSWORD</span> <span className="icon-word-ev">AUTHORING ENVIRONMENT</span></div>
    </div>

    <div className="log-bar-wrap"><span className="logo-bar"></span></div>

    <div className="signin-logo-wrap" title="Logout">
      <div class="mob-logo">
        <span className="signin-logo"></span>
      </div>
      <span class="signin-icon down-arrow-wrap material-symbols-outlined" onClick={()=>LogoutButtonHandler()}>arrow_drop_down</span>

     {
       showLogout && <div className="signin-wrap">
        <span className="signin-text">Signed in as</span>
        <span className="name-text">{props.screenName}</span>
        <div class="logout-wrap" onClick={props.signOut}>
          <span class="logout-text">Logout</span>
          <span class="signin-icon material-symbols-outlined">logout</span>
        </div>
        
      </div> 
       
     } 

<div className="signin-wrap signin-mob-wrap">
    <span className="signin-text">Signed in as</span>
    <div class="signin-text-wrap" onClick={props.signOut}>
      <span className="name-text">{props.screenName}</span>
        <div class="logout-wrap">
          <span class="logout-text">Logout</span>
          <span class="signin-icon material-symbols-outlined">logout</span>
        </div>
      </div>
    </div> 
  </div>
<div className="sidebar-item-wrap" >  
  {/* <div className="sidebar-item" >
    <div className={assignmentClassNames} onClick={() => toggleAssignment()}>
      <span className="asign-icon material-symbols-outlined">Assignment</span>
      <span className="list-head-text">Assignments</span>
      {}
      <span className="signin-icon material-symbols-outlined"> {!openAssignment ?  "keyboard_arrow_right" : "keyboard_arrow_down"}</span></div>
    {openAssignment === true && (
      <ul >
        <li className={activeSection === "01" ? "active" : ""}  onClick={()=>sectionClickHandler("01")}>Ongoing</li>
        <li className={activeSection === "00" ? "active" : ""} onClick={()=>sectionClickHandler("00")}>New</li>
        <li className={activeSection === "02" ? "active" : ""} onClick={()=>sectionClickHandler("02")}>Completed</li>
      </ul>
    )}
  </div> */}
  <div className="sidebar-item" >
    <div className={contestClassNames}  onClick={() => toggleContest()}>
      <span className="asign-icon material-symbols-outlined">TROPhy</span>
      <span className="list-head-text">Contests</span>
      {/* <span class=" material-symbols-outlined signin-icon ">keyboard_arrow_right</span> */}
      <span className="signin-icon material-symbols-outlined">{!openContest ? "keyboard_arrow_right" : "keyboard_arrow_down"}</span>
    </div>
    {openContest === true && (
      <ul>
        <li className={activeSection === "11" ? "active" : ""} onClick={()=>sectionClickHandler("11")}>Ongoing</li>
        <li className={activeSection === "10" ? "active" : ""} onClick={()=>sectionClickHandler("10")}>New</li>
        <li className={activeSection === "12" ? "active" : ""} onClick={()=>sectionClickHandler("12")}>Completed</li>
      </ul>
    )}
  </div>
  </div>
</div>

  );
}

export default Sidebar;
