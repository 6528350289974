import React from 'react';
import { Navbar, Nav } from "react-bootstrap";
import './NavBar.css';
import logo from '../../images/CBSE-logi.png';
import logoIcon from '../../images/Rabbit-left.svg';
const NavBar = () => {
    
  return (  <nav className="navbar">
    <div className="navbar-left">
     <img className='right-logo-icon' style={{"width":"38px"}} src={logo}/>
      <span className="nav-logo-text">CBSE BUDDING AUTHORS PROGRAMME</span>
    </div>
    <div className="navbar-right">
      <span className="nav-icon material-symbols-outlined">help</span>
      <a className="nav-right-text" href="#help">Help</a>
      <span className="nav-separation"></span>
      <span className="nav-icon material-symbols-outlined">tour</span>
      <a className="nav-right-text" href="#tour">Tour</a>
      <div className='right-logo'>
      { <img className='left-logo-icon' style={{"width":"100%"}} src={logoIcon}/>}</div>
    </div>
  </nav>
  );
}

export default NavBar;
