import React, {useEffect, useState} from 'react';

import PaperSvg  from './paperSvg';
import './Paper.css';
const Paper = ({activeContent, data, onPaperClick})=>  {
        console.log(data);
        data = data.filter((contest)=>{
          return contest.section === activeContent;
        })
         function compareDate(inputDateStr) {
    // Assuming the input format is always "M/D/YYYY"
    const inputDateParts = inputDateStr.split('/');
    const inputDate = new Date(+inputDateParts[2], +inputDateParts[0] - 1, +inputDateParts[1]);
    
    // Get the current date
    const currentDate = new Date();
    
    // Compare the two dates
    if (inputDate > currentDate) {
        return true;
    } else {
        return false;
    }
}
for(let i=0; i<data.length; i++){
        const contest = data[i];
        if(compareDate(data[i].endDate)){
                    data[i].isContestClosed = false;
                    data[i].opacity = 1;
             }else{
                    data[i].isContestClosed = true;
                    data[i].opacity = 0.5;  
            }
}
        let heading = "";
        switch(activeContent){
          case "00":
            heading = "New Assignments";
            break;
          case "01":
            heading = "Ongoing Assignments";
            break;
          case "02":
            heading = "Completed Assignments";
            break;
          case "10":
            heading = "New Contests";
            break;
          case "11":
            heading = "Ongoing Contests";
            break;
          case "12":
            heading = "Completed Contests";
            break;
          case "13":  
        }
        const paperClickHandler = (index)=>{
          const contest = data[index];
          if(contest.fileStatus === "0"){   
            if(contest.section === "00"){
             if(!contest.isContestClosed){
                     onPaperClick(false, true, {companyId:contest.companyId, id:contest.id, name:contest.name, words : contest.wordCount, status : contest.fileStatus}, true);
             }
              
            }else{
                    if(!contest.isContestClosed){
              onPaperClick(true,false,{companyId:contest.companyId, id:contest.id, name:contest.name, words : contest.wordCount, status : contest.fileStatus});
                    }
            }
            
          }else if(contest.fileStatus === "1"){
                  if(!contest.isContestClosed){
            onPaperClick(false,true, {companyId:contest.companyId,id:contest.id, name:contest.name, words : contest.wordCount, status : contest.fileStatus});
                  }
          }else if(contest.fileStatus === "2"){
            onPaperClick(false,true, {companyId:contest.companyId,id:contest.id, name:contest.name, words : contest.wordCount, status : contest.fileStatus});
          }
        }
    return (<>
      <div className="main-content"><div>
      <div className='book-name-wrap'>
      <span className='book-name'>{heading}</span>
        <div className='shortcut-wrap'>
          <span className='sort-text'>sort by:</span>
          <select className='select-wrap'><option>Submission Date</option></select>
        </div>
      </div>
            <div className='shortcut-wrap shortcut-mob-wrap'>
          <span className='sort-text'>sort by:</span>
          <select className='select-wrap'><option>Submission Date</option></select>
        </div>
    <div className="assignments-list">
      {data.map((item, index) => (
        <div className="assignment-card" key={index} onClick={()=>paperClickHandler(index)}>
          <PaperSvg name={item.name}  subject={item?.subject} opacitay = {item?.opacity}/>
         {((activeContent === "00" || activeContent === "10") && item.isContestClosed) &&(<div className='due-wrap'> <span className='date-text'>Contest Closed</span></div>)}
          {((activeContent === "01" || activeContent === "11") && item.isContestClosed) &&(<div className='due-wrap'> <span className='date-text'>Contest Closed</span></div>)}     
           {((activeContent === "00" || activeContent === "10") && !item.isContestClosed)  &&(<div className='due-wrap'><span className='due-text'>Due on</span> <span className='date-text'>{item.endDate}</span></div>)}
          {((activeContent === "01" || activeContent === "11") &&  !item.isContestClosed)  &&((<progress id="file" value={""+Math.round(Number(item.submittedWords)/Number(item.wordCount.max)*100)} max="100"> 32% </progress>))}       
        </div>
      ))}
    </div>
  </div></div>
  </>)
  
  }

export default Paper;
