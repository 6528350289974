import { useEffect, useState} from "react";
import Swal from 'sweetalert2';

import {Amplify, API} from 'aws-amplify';
import {library} from '@fortawesome/fontawesome-svg-core'
// import {far} from '@fortawesome/free-regular-svg-icons'
// import awsmobile from '../../aws-exports';
// import amplifyconfig from '../../amplifyconfiguration.json';
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContestShelf from "../ContestShelf/ContestShelf";

library.add(faPencilAlt);


// Amplify.configure(amplifyconfig);
// Amplify.configure(awsmobile);

export default function PageMajikEditor({fileObj, signOut}){    
    const {companyId,chapters, id, name,  userId, wordsCount, screenName} = fileObj;
    const [chapterName, setChapterName] = useState("ChapterName");
    const [bookName, setBookName] = useState("BookName");
    const [openContestShelf, setOpenContestShelf] = useState(false);
    const [wordCount, setWordCount] = useState(10);
    const [Loader, setLoader] = useState("loader");
    const [buttonStyle, setButtonStyle] = useState({"width":"75px","pointerEvents":"none","opacity":0.3});
    const [ExitbuttonStyle, setExitbuttonStyle] = useState({"width":"75px","pointerEvents":"none","opacity":0.3});
    const [buttonParentStyle, setButtonParentStyle] = useState({"cursor":"not-allowed"});
    const userDetails =  fileObj;
    const status = userDetails.status;
    const firstChapter = chapters[0];
    const filePath = firstChapter.path;
    window.customeditor["fileEntryId"] = firstChapter.id;
    let isFirst = false;
    async function readFileFromS3(){      
    if(typeof userDetails.words != "undefined"){
        if(typeof userDetails.words != "undefined"){
            try{
                window.editor.config.wordcount.maxWordCount = Number(userDetails.words.max);
            }catch(err){}                            
            setWordCount(window.editor.config.wordcount.maxWordCount);
        }else{
            window.editor.config.wordcount.maxWordCount = 300;   
        }
    }else{
        window.editor.config.wordcount.maxWordCount = 300;   
    }
    if(typeof userDetails.isNew === "undefined"){
            const apiName = "apicontestassignment";
            const path = "/contest/content";
            const init = {
                body : {
                    s3Path : filePath
                }
            };            
            API.post(apiName, path, init ).then(response=>{
                CheckLocalStorage(response.content);
            }).catch(err=>{
                console.log(err);
            });                        
    }else{
        document.querySelector('#maxWordCount').innerText = "/"+window.editor.config.wordcount.maxWordCount;
        isFirst = true;
        editorSaveHandler();
    }
    if(status != "2"){
        setButtonStyle({});
    }
    setExitbuttonStyle({});
    setButtonParentStyle({});
   }
   
 
   function CheckLocalStorage(data){
    const storage = window.getPagemajikStorage();
    if(storage){
        Swal.fire({
            icon: 'question',
            title: '',
            html: `A more recent autosaved version of your document is available on your computer. <br/> Choose "Yes" to use the version from your computer or "No" to use the version from the server.`,
            confirmButtonText:'Yes',
            cancelButtonText :'No',
            customClass:'swal-width',
            showConfirmButton : true,
            showCancelButton : true,
            
            }).then((result) => {
            /* Read more about handling dismissals below */
            if(result.isConfirmed){             
              Swal.close();
              window.removePagemajikStorage();
              setDataInEditor(storage);              
            }else{
              Swal.close();
              window.removePagemajikStorage();
              setDataInEditor(data) ;
            }
            });
    }else{
        setDataInEditor(data);
    }
   }
   function setDataInEditor(data){
        window.editor.setData(data,function(){
            this.resetUndo();
            if(status === "2"){
                this.setReadOnly();
            }
        });
   }
   
   async function exitHandler(){   
    if(status === "2"){
        withoutSave();
    }else{
        Swal.fire({
            icon : "info",
            title: "Would you like to save the changes?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't Save`,
            customClass :{
                confirmButton : 'save-btn',
                denyButton: 'dontsave-btn',
                cancelButton : 'cancel-btn' 
            }
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                 saveandExit();
            } else if (result.isDenied) {
                withoutSave();
            }
          });
    }
     
   }
   async function saveandExit(){        
       await editorSaveHandler();
      setOpenContestShelf(true);
   }
   async function withoutSave(){
        window.removePagemajikStorage();
        setOpenContestShelf(true); 
   }
   window.saveContestFile = function(){
     if(status != "2"){
        editorSaveHandler();
     }
        
   }
   const listenerfuntion =  (event) => {
	    event.preventDefault();
        event.returnValue='';
    }
   useEffect(function(){
    window.addEventListener('beforeunload', listenerfuntion);
    return ()=>{
        window.removeEventListener('beforeunload', listenerfuntion);
    }
   },[]);
    async function  editorSaveHandler(){        
        if(!isFirst){
            if(document.querySelector('a.cke_button__undo').classList.contains("cke_button_disabled") || window.dataChanged === false ){
                Swal.fire({
                    icon: "info",
                    title: "No new changes have been detected.",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                  });
                  window.removePagemajikStorage();
                return false;
            }
        }        
        const content = window.editor.getData();
        const apiName = "apicontestassignment";
            const path = "/contest/save/content";
            const init = {
                body : {
                    path : filePath,
                    companyId : userDetails.companyId,
                    id : userDetails.id,
                    screenName : userDetails.screenName,                    
                    content,
                    words : document.querySelector('#wordCount').innerText
                }
            };  
        API.post(apiName,path,init).then(response=>{
            console.log("Response :",response.status);
            if(response.status === "done"){
                if(!isFirst){
                    window.dataChanged = false;
                    Swal.fire({
                        icon: "success",
                        title: "Your request to save changes completed successfully.",
                        showConfirmButton: true,
                        confirmButtonText: "OK",
                    });
                    window.removePagemajikStorage();
                }else{
                    isFirst = false;
                }                
            }else{
                window.addPagemajikStorage();
                Swal.fire({
                    icon: "error",
                    title: response.err.message,
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                });        
            } 
            
        }).catch(err=>{
            window.addPagemajikStorage();
            Swal.fire({
                icon: "error",
                title: err.message,
                showConfirmButton: true,
                confirmButtonText: "OK",
            });
        });                        
}
    // function uploadImageIntoS3(file){
    //     const imagePath = `${filePath.substring(0,filePath.lastIndexOf("/"))}/images/${file.name}`;
    //     const params = {
    //         Bucket: config.bucketName,
    //         Key: imagePath,
    //         Body: file,
    //       }
    //     s3.upload(params, function(err,data){
    //         if(err === null){
    //             Swal.fire('Success', 'Image uploaded successfully', 'success');
              
    //         }else{
    //             Swal.fire('Error', 'Error uploading image', 'error');
    //         } 
    //       }); 
    // }
    function updateStatusIntoDynamoDB(){

        const apiName = "apicontestassignment";
        const path = "/contest/save/json";
        const init = {
            body : {
                companyId : userDetails.companyId,
                uniqueId : userDetails.id,
                screenName : userDetails.screenName               
            }
        }; 
        API.post(apiName,path,init).then(async response=>{
            console.log(response)
            if(response.status === "done"){
                let content = window.editor.editable().$;
                content.setAttribute("contenteditable","false");
                content.classList.add("contest-finished");
                isFirst = true;
                Swal.fire({
                    icon: "success",
                    title: "Your request to submit completed successfully.",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                });                
                await saveandExit();
            }else if(response.status === "error"){
                Swal.fire({
                    icon: "error",
                    title: response.error.message,
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                  });
            }
                     
        }).catch(err=>{
            Swal.fire({
                icon: "error",
                title: err.message,
                showConfirmButton: true,
                confirmButtonText: "OK",
              });
        });
    }
    function updateBookName(bookName){
        const apiName = "apicontestassignment";
        const path = "/contest/update/bookname";
        const init = {
            body : {
                companyId : userDetails.companyId,
                uniqueId : userDetails.id,
                screenName : userDetails.screenName,  
                bookName                
            }
        }; 
        API.post(apiName,path,init).then(async response=>{
            if(response.status === "done"){
                setBookName(bookName);
                Swal.fire({
                    icon: "success",
                    title: "Book successfully renamed.",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                });
                
            }else if(response.status === "error"){
                Swal.fire({
                    icon: "error",
                    title: response.error.message,
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                  });
            }
                     
        }).catch(err=>{
            Swal.fire({
                icon: "error",
                title: err.message,
                showConfirmButton: true,
                confirmButtonText: "OK",
              });
        });
    }
    function checkWordCount(editor) {
        let message = "";
        const maxWordCount = editor.config.wordcount.maxWordCount;
        const currentWordCount = Number(document.querySelector('#wordCount').innerText);
        if(maxWordCount > -1 && currentWordCount > maxWordCount){
            return `You have crossed the word limit. Number of words should be between ${Math.round(window.editor.config.wordcount.maxWordCount/2)} and ${window.editor.config.wordcount.maxWordCount}. Remove some text and try again.`
        }else if(currentWordCount < maxWordCount/2){
            return `Your submission is below the mininum word limit. Number of words should be between ${Math.round(window.editor.config.wordcount.maxWordCount/2)} and ${window.editor.config.wordcount.maxWordCount}. Add some text and try again.`;
        }
        return message;
      }
    function finishHandler(){
        let wordCounts = checkWordCount(window.editor);
        if(wordCounts.length > 0){
            limitExistSwal(wordCounts);
        } else{
            Swal.fire({
                title: "",
                html: `Clicking "Submit" will submit your work, and you won't have the option to edit it further. <br/> Do you want to proceed and submit?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Submit",
                denyButtonText: `Cancel`,
                dangerMode: true,
            })
            .then((willFinish) => {
                if (willFinish.isConfirmed) {
                // Perform the necessary actions when the user confirms finishing
                updateStatusIntoDynamoDB();
                }
            });
        }
        
        
    }
    useEffect(()=>{      
        initializeEditor();
        setChapterName(firstChapter.name);
        setBookName(userDetails.bookName);
         window.limitExistSwal = limitExistSwal;
        // window.CKEDITOR.uploadImageHandler = uploadImage;
        window.CKEDITOR.saveHandler = editorSaveHandler;
        // AWS.config.update({
        //     region: config.region,
        //     accessKeyId: config.accessKeyId,
        //     secretAccessKey: config.secretAccessKey,
        // });
    },[]) /**it's triggered only once */    
    function limitExistSwal(message){
        // Swal.fire('', message, 'error');
        Swal.fire({
            icon: "error",
            title: message,
            showConfirmButton: true,
            confirmButtonText: "OK",
        }); 
        // Swal.fire({width: '30vw',icon:'success',title:'Your request to send mail and move stage is being processed. You may proceed with your work.',
        // });
    }
    function bookNameEditHandler(){
        Swal.fire({
            icon : '',
            title : 'Rename',
            customClass : {
                container : 'swal-pagemajik',
                validationMessage : 'pagemajik-validation'
            }, 
            html : `
            <div class="editlabel-name"><label>Current Name</label><input type="text" title="${bookName}" id="currentBookName" disabled value="${bookName}" /> </div>
            <div class="editlabel-input"><label> New Name <span>*</span></label> <input type="text" id="newBookName"/> </div>`,
            showCancelButton : true,
            showConfirmButton : true,
            confirmButtonText : 'Save',
            preConfirm : function(){
                const format = /[&$@=;/:+,?{^}%`~#*<>\/"|>\\]/;
                const newBookName = document.querySelector('#newBookName').value;               
                if(newBookName.trim().length === 0){ 
                    Swal.showValidationMessage(
                        'Mandatory fields need to be filled.'
                    )
                }else if(format.test(newBookName)){
                    const message = 'The book name contains one or more of the following characters. Please remove them and try again: & $ @ = ; / : + , ? { ^ } % `~ # * < > \\ " |';
                    Swal.showValidationMessage(message);
                    //Swal.fire('', message, 'error');            
                    return false;
                }else if(newBookName.length > 100){
                    //Swal.fire('', 'The book name exceeds 100 characters. Please try again with a shorter name.',"error");
                    Swal.showValidationMessage('The book name exceeds 100 characters. Please try again with a shorter name.');
                    return false;
                }else if(newBookName.trim() === bookName.trim()){
                    Swal.showValidationMessage('The given name is identical to the current one. Select a different name and try again.');
                    return false;
                }                
            },
        }).then(function(willSave){
            if(willSave.isConfirmed){
                updateBookName(document.querySelector('#newBookName').value);
            }
        });
    }
    function initializeEditor(){
        if(typeof window.CKEDITOR.instances.editor1 === "undefined"){
            let editor = "";
            window.editor  = editor= window.CKEDITOR.replace('editor1',{
                toolbarGroups :[{
                        name:'styles'
                    },{
                        name : 'basicstyles'
                    },{
                        name : 'list'
                    },{
                        name : 'undo'
                    },{
                        name : 'find'
                    },{
                        name:'authorgroup'
                    }]
            });
            let $window = window;
            editor.on('instanceReady',function(evt){
                window.setHeight();
                readFileFromS3();
                setLoader("loader page-hide");
            });
        }  
        
        
    }
    // const uploadImage = async () => {
    //     const { value: file } = await Swal.fire({
    //       title: 'Upload Image',
    //       input : 'file',
    //       inputAttributes: {
    //         accept: 'image/*',
    //         'aria-label': 'Upload your image',
    //       },
    //       showCancelButton: true,
    //       confirmButtonText: 'Upload',
    //       cancelButtonText: 'Cancel',
    //       preConfirm: async () => {
    //         const selectedFile = document.querySelector('.swal2-file,input[type="file"]').files[0];
    //         if (!selectedFile) {
    //           Swal.showValidationMessage('Please select an image to upload');
    //           return false;
    //         }
    //         uploadImageIntoS3(selectedFile);
    //       },
    //       showLoaderOnConfirm: true,
    //       allowOutsideClick: () => !Swal.isLoading(),
    //     });
    //   } /**: chapter name  */
    return(   
        <>{
            !openContestShelf ?<div className="sample" style={{position:"relative"}} >
            <div className={Loader}></div>
         <div className="bookname"><span className="book-head" title={bookName}>{bookName}</span><FontAwesomeIcon title="Rename" icon="fa-pencil-alt" onClick={bookNameEditHandler}/> <span>{chapterName}</span></div> 
                <div className="toolsbarend" style={buttonParentStyle}>
                      <div className="topbar ripple" data-ripple-color="#f9f9f9" style={buttonStyle} id="savetab" onClick={finishHandler}>
                            <span className="material-symbols-outlined">SELECT_CHECK_BOX</span>
                            <span>Submit</span>
                        </div>
                        <div className="topbar ripple" data-ripple-color="#f9f9f9" style={buttonStyle} id="savetab" onClick={editorSaveHandler}>
                            <span className="material-symbols-outlined">SAVE</span><span>Save</span>
                        </div>
                        <div className="topbar ripple" data-ripple-color="#f9f9f9" style={ExitbuttonStyle} id="exittab"  onClick={exitHandler} >
                            <span className="material-symbols-outlined">CLOSE</span><span>Exit</span>
                        </div>
                    </div>
                <textarea id="editor1" cols="10" rows="10"></textarea>
                
        </div> : <ContestShelf screenName={screenName} signOut={signOut} userId={userId}/>
        
        }</>
    
        
    
    );


};
