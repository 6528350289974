import React, {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import Swal from 'sweetalert2';
import { v4 as uuid } from "uuid";
import PageMajikEditor from '../PageMajikEditor/PageMajikEditor';
import SetupForm from '../SetupForm/SetupForm';
import NavBar from '../NavBar/NavBar';
import Sidebar from '../Sidebar/Sidebar';
import Paper from '../Paper/Paper';

import './ContestShelf.css';

// const StyledAppBar = styled(AppBar)(({ theme }) => ({  
//   zIndex: theme.zIndex.drawer + 1,
// }));

// const StyledDrawer = styled(Drawer)({
//   width: drawerWidth,
//   flexShrink: 0,
//   '& .MuiDrawer-paper': {
//     width: drawerWidth,
//     boxSizing: 'border-box',
//   },
// });

// const DrawerContainer = styled('div')({
//   overflow: 'auto',
// });

// const Main = styled('main')(({ theme }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
// }));

// const SubBar = styled(Box)(({ theme }) => ({
//   padding: theme.spacing(2),
//   backgroundColor: theme.palette.background.paper,
// }));

const  ContestShelf = ({screenName, signOut, userId})=>{
  const[openBookFrom, setBookFrom] = useState(false);
  const[openPageMajikEditor, setPageMajikEditor] = useState(false);
  const [contestObject, setContest] = useState({});
  const [activeContent, setActiveContent] = useState('10'); // State to track the active content
  const [data,setData] = useState([]);
  const [isDataReturned, setDataReturned] = useState(true);
  async function getAllContest(screenName){  
    const apiName = "apicontestassignment";
    const path = "/contest/getcontest/byuser";
    const init = {
      body : {
        screenName
      }
    }
    API.post(apiName,path,init).then(response=>{
      response.contest.map((contest)=>{
          if(contest.type.toLowerCase() === "assignment"){
            contest.section = "0"+contest.fileStatus;
          }else if(contest.type.toLowerCase() === "contest"){
            contest.section = "1"+contest.fileStatus;
          }
      });
      console.log("response",response);
      if(response.contest.length === 1){ /**Temporary */
        console.log("Section triggered", response.contest[0].section);
        setActiveContent(response.contest[0].section)
      }
      setData(response.contest);
      
    }).catch(err=>{
      console.log(err);
      Swal.fire({
        icon: "error",
        title: err.message,
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
    })
  }
  function paperClickHandler(openFrom, openEditor, contest, isNewAssignment=false){
    contest.screenName = screenName;
    contest.userId = userId;
    if(isNewAssignment){
      createChapterForAssignment(openFrom, openEditor, contest)
    }else{
      
      if(!openFrom){
        getChapterDetails(openFrom, openEditor, contest);
      }else{
        setContest(contest);
        setBookFrom(openFrom);
        setPageMajikEditor(openEditor);
      }
    }
    
  }
  function createChapterForAssignment(openFrom, openEditor, contest){
    const chapterId = uuid();
    const apiName = "apicontestassignment";
        const path = "/contest/create/content";
        const init = {
            body : {
               bookName : contest.name.trim(),
               uniqueId : contest.id,
               chapterId,
               chapterName : "",
               screenName : contest.screenName,
               userId : contest.userId,
               companyId : contest.companyId,
               }
        };
    API.post(apiName, path, init).then(response=>{
        console.log(response);
        if(response.status === "done"){                
            contest.chapters = [{
                name: "",
                id: chapterId,
                path : response.data.s3Path
                
            }];
            contest.bookName = contest.name.trim();
            contest.isNew = true;                
            //setOpenEditor(true);
            setContest(contest);
            setBookFrom(openFrom);
            setPageMajikEditor(openEditor);
        }else if(response.status === "error"){
            console.warn("error needs to handle",response.error);
        }
    }).catch(err=>{
        
      Swal.fire({
        icon: "error",
        title: err.message,
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
    })
  }
  function getChapterDetails(openFrom, openEditor, contest){
    console.log(contest);
    const apiName = "apicontestassignment";
    const path = "/contest/getchapter/details";
    const init = {
      body : {
        companyId : contest.companyId,
        uniqueId : contest.id,
      }
    }
    API.post(apiName,path,init).then(response=>{
      console.log(response);
        contest.bookName = response.bookName;
        contest.chapters = [{
          id : response.id,
          name : response.name,
          path : response.path

        }]
        setContest(contest);
      setBookFrom(openFrom);
      setPageMajikEditor(openEditor);
    }).catch(err=>{
      console.log(err);
      Swal.fire({
        icon: "error",
        title: err.message,
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
    })
  }
  useEffect(()=>{
    //if(isDataReturned){
      if(window.CKEDITOR.instances.editor1 != "undefined"){
        try{
          window.CKEDITOR.instances.editor1.destroy();
        }catch(err){
          //console.log(err);
        }
        
      }
      getAllContest(screenName);
    //   setDataReturned(false);  
    // }
    
  },[]);
  const handleSidebarClick = (section) => {
    setActiveContent(section);
  };
    return (<>{
      !openBookFrom && !openPageMajikEditor ?
      <div className="contest-board">
      <NavBar />
      <div className="contest-container">
        <Sidebar onSectionClick={handleSidebarClick} screenName={screenName} signOut={signOut} data={data}/>
        <Paper activeContent={activeContent} data={data} heading={screenName}  signOut={signOut} onPaperClick={paperClickHandler}/>
      </div>
    </div>
    : openBookFrom ? <SetupForm  contest={contestObject}   signOut={signOut} /> : <PageMajikEditor signOut={signOut}  fileObj={contestObject}/>
    }</>
    );
  
}

export default ContestShelf;