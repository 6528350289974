
import { useState } from 'react';
import  Container from 'react-bootstrap/Container'
import  Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChapterFormHeading from "./ChapterFormHeading";
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { v4 as uuid } from "uuid";
import PageMajikEditor from '../PageMajikEditor/PageMajikEditor';
import Swal from 'sweetalert2';
import { Amplify, API } from 'aws-amplify';
// import awsmobile from '../../aws-exports';
// import amplifyconfig from '../../amplifyconfiguration.json';


// Amplify.configure(amplifyconfig);
// Amplify.configure(awsmobile);
const initialDetails = {
    bookName: '',
    chapterName: ''
}
export default function SetupForm({contest, signOut, userId}){
    const [writingButton, setWritingButton] = useState("newbtn  ld-ext-right btn btn-primary");
    const [SetUpFormDetails, setOpenSetUpFormDetails] = useState(initialDetails);
    const [openEditor, setOpenEditor] = useState(false);
    const setUpInputChangeHandler = (e) => {
        var tempVal = {
          ...SetUpFormDetails
        }
        tempVal[e.target.id] = e.target.value;        
        var format = /[&$@=;/:+,?{^}%`~#*<>\/"|>\\]/;        
        if(format.test(e.target.value)){
            const message = 'The book name contains one or more of the following characters. Please remove them and try again: & $ @ = ; / : + , ? { ^ } % `~ # * < > \\ " |';
            Swal.fire('', message, 'error');            
        }else if(e.target.value.length > 100  && e.target.value.length != 0){
            console.log(e.target.value.length)
            Swal.fire('', 'The book name exceeds 100 characters. Please try again with a shorter name.',"error");
        }
        
        setOpenSetUpFormDetails(tempVal);
  }
  
  const createChapter = (evt)=>{
    var format = /[&$@=;/:+,?{^}%`~#*<>\/"|>\\]/;
    if(SetUpFormDetails.bookName.trim().length === 0){ //SetUpFormDetails.chapterName.trim().length === 0 ||
        //
        Swal.fire('Mandatory fields need to be filled.','', 'error');
        
    }else if(format.test(SetUpFormDetails.bookName)){
            const message = 'The book name contains one or more of the following characters. Please remove them and try again: & $ @ = ; / : + , ? { ^ } % `~ # * < > \\ " |';
            Swal.fire('', message, 'error');            
            return false;
    }else if(SetUpFormDetails.bookName.length > 100){
        Swal.fire('', 'The book name exceeds 100 characters. Please try again with a shorter name.',"error");
        return false;
    }else{
        setWritingButton("newbtn  ld-ext-right btn btn-primary running");
        //const userDetails = contest
        evt.preventDefault();
        const chapterId = uuid();
        const apiName = "apicontestassignment";
            const path = "/contest/create/content";
            const init = {
                body : {
                   bookName : SetUpFormDetails.bookName.trim(),
                   uniqueId : contest.id,
                   chapterId,
                   chapterName : SetUpFormDetails.chapterName,
                   screenName : contest.screenName,
                   userId : contest.userId,
                   companyId : contest.companyId,
                   }
            };
        API.post(apiName, path, init).then(response=>{
            console.log(response);
            if(response.status === "done"){                
                contest.chapters = [{
                    name: SetUpFormDetails.chapterName,
                    id: chapterId,
                    path : response.data.s3Path
                    
                }];
                contest.bookName = SetUpFormDetails.bookName.trim();
                contest.isNew = true;                
                setOpenEditor(true);
            }else if(response.status === "error"){
                console.warn("error needs to handle",response.error);
            }
        }).catch(err=>{
            console.warn(err);
        })
        
    }   
}
    return(
        <>
        {!openEditor ? (<Container>
            <Row>
                <Col>
                <div className='chapgroup'>
                <div className="chapname">
                    <ChapterFormHeading  contestName={contest.name}/>
                    <Form className='chapform'>
                        <Form.Group  className='chapform-lable'>

                        <span className="material-symbols-outlined">book_2</span>
                             <Form.Label  className='chap-lable' htmlFor='bookname'>Book Name <span className='chapter-req'>*</span></Form.Label>
                            <Form.Control type='text' id="bookName" className='chapter input100' placeholder='Enter book name' onChange={setUpInputChangeHandler} value={SetUpFormDetails.bookName} />
                            <span className='focus-input100' data-placeholder='Book Name'></span>
                        </Form.Group>
                        <Form.Group  className='chapform-lable page-hide'>

                        <span className="material-symbols-outlined">auto_stories</span>
                             <Form.Label  className='chap-lable' htmlFor='chaptername'>Chapter Name *</Form.Label> 
                            <Form.Control required type='text' id="chapterName" className='chapter input100' placeholder='Enter chapter name' onChange={setUpInputChangeHandler} value={SetUpFormDetails.chapterName}/>
                            <span className='focus-input100' data-placeholder='Chapter Name'></span>
                        </Form.Group>
                        <Button type='submit' id="Submitfirst"  className={writingButton} onClick={createChapter}>Start Writing <span className='ld ld-ring ld-spin'></span></Button>
                        
                        <div className="pg-contest"><span className="contest">CBSE Budding Authors Programme</span></div>
                    </Form>
                </div></div>
                </Col>
            </Row>
        </Container>)
        : (<PageMajikEditor signOut={signOut} fileObj = {contest}/>)
        }
        </>
        
    );
};