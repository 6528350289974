import React from 'react';
import {BrowserRouter ,Routes, Route} from 'react-router-dom'
// import SetupFrom from './Components/SetupForm/SetupForm';
// import PageMajikEditor  from './Components/PageMajikEditor/PageMajikEditor';
// import Login from './Components/Login/Login';
// import ContestShelf  from './Components/ContestShelf/ContestShelf';
import Login from './Components/Login/Login';
// import Authenticator from './Components/Authenticator/Authenticator';
import './App.css';
import "@fontsource/lato"; 

function App() {
  return (
    <React.StrictMode>
    <BrowserRouter>
      <Routes>
         <Route path='/' element={<Login/>} />
         {/* <Route path='/auth' element={<Authenticator/>} /> */}
         {/* <Route path='/pagemajik-editor' element={<PageMajikEditor />} />
         <Route path='/create-chapter' element={<SetupFrom />} />
         <Route path='/login' element={<Login />} /> */}
      </Routes>
    </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
